import React from "react";
import { Box, Stack, Typography, Button, Divider } from "@mui/material";
import type { GroupMember, GroupStatsBoxScore } from "../../shared/types";

type InputProps = {
  stats: any;
  members: GroupMember[];
  handleOnViewAllClick: () => void;
}

/**
 * Session summary component
 * @param session (FBSession)
 * @param clusters (FBClusterTagV4[])
 * @param shots (ClusterActionsV4[])
 * @param owner (FBUser)
 */
const GroupStatsSection = ({ stats, members, handleOnViewAllClick }: InputProps) => {

  const getRaceContent = () => {
    const sortedAndFilteredStats = stats
      .filter((stat: GroupStatsBoxScore) => {
        const member = members.find(member => member.userId === stat.userId);
        return member !== undefined;
      })
      .sort((a: GroupStatsBoxScore, b: GroupStatsBoxScore) => {
        let aValue = a.points / a.numSessions;
        let bValue = b.points / b.numSessions;
        return bValue - aValue;
      });

    const getStatValue = (stat: GroupStatsBoxScore) => {
      return stat.points / stat.numSessions;
    };
    
    const maxValue = sortedAndFilteredStats.length > 0 ? getStatValue(sortedAndFilteredStats[0]) : 0;

    const getRow = (index: number, user: GroupMember, stat: GroupStatsBoxScore) => {
      const place = `${index + 1}.`;
      const username = user.username;
      let displayedStat = (stat.points / stat.numSessions).toFixed(1);
      if (displayedStat === "0.0") displayedStat = "0";

      let displayedDelta = '';
      let deltaColor = 'gray';
      
      let delta = stat.pointsPerGameChange ?? null;
      
      if (delta === null || delta === undefined || delta === 0) {
        displayedDelta = '-';
      } else if (delta > 0) {
        displayedDelta = `▲ ${delta.toFixed(1)}%`;
        deltaColor = '#1CBE49';
      } else if (delta < 0) {
        displayedDelta = `▼ ${Math.abs(delta).toFixed(1)}%`;
        deltaColor = '#E52E4D';
      }

      const currentValue = getStatValue(stat);
      const progressWidth = maxValue > 0 ? (currentValue / maxValue) * 100 : 0;

      const AVATAR_SIZE = 24;

      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: 40,
            borderRadius: 8,
            bgcolor: '#16181A',
            gap: '8px',
          }}
        >
          <Box
            sx={{
              flex: '0 0 33%',
              display: 'flex',
              flexDirection: 'row',
              gap: '6px',
              minWidth: 0,
              overflow: 'hidden',
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontSize: 14,
                width: 20,
                fontWeight: 'bold',
                flexShrink: 0,
              }}
            >
              {place}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '8px',
                minWidth: 0,
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  flexShrink: 0,
                }}
              >
                {user.profilePicture ? (
                  <img 
                    src={user.profilePicture} 
                    alt="profile" 
                    width={AVATAR_SIZE} 
                    height={AVATAR_SIZE} 
                    style={{ borderRadius: '50%' }} 
                  />
                ) : (
                  <img 
                    src="/images/blank-profile.webp" 
                    alt="profile" 
                    width={AVATAR_SIZE} 
                    height={AVATAR_SIZE} 
                    style={{ borderRadius: '50%' }} 
                  />
                )}
              </Box>
              <Typography
                sx={{
                  color: "white",
                  fontSize: 14,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  minWidth: 0,
                  flex: 1,
                }}
              >
                {username}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              flex: '0 0 67%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '8px'
            }}
          >
            <Box
              sx={{
                flex: 5,
                height: 8,
                borderRadius: '1000px',
              }}
            >
              <Box
                sx={{
                  bgcolor: "#1CBE49",
                  height: 8,
                  borderRadius: '1000px',
                  width: `${progressWidth}%`,
                }}
              />
            </Box>
            <Box
              sx={{
                flex: 6,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '8px'
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: 14,
                  textAlign: "center",
                  fontWeight: 'bold',
                }}
              >
                {displayedStat}
              </Typography>
              <Typography
                sx={{
                  color: deltaColor,
                  fontSize: 12,
                  textAlign: "center",
                  fontWeight: 'bold',
                  width: 60,
                }}
              >
                {displayedDelta}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    };

    return (
      <Box>
        {sortedAndFilteredStats.map((stat: GroupStatsBoxScore, index: number) => {
          const member = members.find(member => member.userId === stat.userId)!;
          return (
            <Box key={`box-score-${stat.userId}`}>
              {getRow(index, member, stat)}
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: '#16181A',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        gap: 1,
      }}
    > 
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Typography
            sx={{ 
              color: "white",
              fontWeight: "bold",
            }}
          >
            Stats
          </Typography>
        </Stack>
        <Button
          onClick={() => handleOnViewAllClick()}
          sx={{
            color: "#80B5FF",
            textTransform: 'none',
          }}
        >
          View all
        </Button>
      </Box>
      <Divider
        sx={{
          bgcolor: '#202429',
        }}
      />
      {stats ? (
        getRaceContent()
      ) : (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pb: 1
          }}
        >
          <Typography
            sx={{
              color: "#ABB2BF",
              fontSize: 14
            }}
          >
            No stats yet
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default GroupStatsSection;
