import React, { useCallback, useEffect, useState } from "react";
import { Box, Stack, Typography, Button, Divider } from "@mui/material";
import type { GroupMember } from "../../shared/types";

type InputProps = {
  numberOfMembers :number;
  members: GroupMember[];
  userIsMember: boolean;
  handleOnViewAllClick: () => void;
  handleOnJoinClick: () => void;
}

/**
 * Session summary component
 * @param session (FBSession)
 * @param clusters (FBClusterTagV4[])
 * @param shots (ClusterActionsV4[])
 * @param owner (FBUser)
 */
const GroupMembersSection = ({ numberOfMembers, members, userIsMember, handleOnViewAllClick, handleOnJoinClick}: InputProps) => {
  const [displayedMembers, setDisplayedMembers] = useState<GroupMember[]>([]);
  const [containerWidth, setContainerWidth] = useState(0);
  const AVATAR_SIZE = 32;
  const AVATAR_OVERLAP = 2;
  const CONTAINER_PADDING = 16;

  // Calculate how many avatars can fit
  const calculateVisibleAvatars = useCallback(() => {
    if (containerWidth === 0) return 0;
    
    const effectiveAvatarWidth = AVATAR_SIZE - AVATAR_OVERLAP;
    const availableWidth = containerWidth - (CONTAINER_PADDING * 2);
    const maxAvatars = Math.floor(availableWidth / effectiveAvatarWidth);
    
    return Math.min(maxAvatars, members.length);
  }, [containerWidth, members.length]);

  useEffect(() => {
    if (containerWidth > 0) {
      const numberOfVisibleAvatars = calculateVisibleAvatars();
      const visibleMembers = members.slice(0, numberOfVisibleAvatars);
      setDisplayedMembers(visibleMembers);
    }
  }, [containerWidth, calculateVisibleAvatars, members]);

  return (
    <Box
      sx={{
        height: userIsMember ? 120 : 176,
        width: "100%",
        bgcolor: '#16181A',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Typography
            sx={{ 
              color: "white",
              fontWeight: "bold",
            }}
          >
            Members
          </Typography>
          <Typography
            sx={{ 
              color: "#78808D",
            }}
          >
            {numberOfMembers}
          </Typography>
        </Stack>
        <Button
          onClick={() => handleOnViewAllClick()}
          sx={{
            color: "#80B5FF",
            textTransform: 'none',
          }}
        >
          View all
        </Button>
      </Box>
      <Divider
        sx={{
          bgcolor: '#202429',
        }}
      />
      <Box 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'center',
        }}
      >
        <Box 
          sx={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            '& > *:not(:first-of-type)': {
              marginLeft: '-8px',
            }
          }}
          ref={(el) => {
            if (el) {
              setContainerWidth((el as HTMLElement).getBoundingClientRect().width);
            }
          }}
        >
          {displayedMembers.map((member) => (
            <Box
              key={`members-${member.userId}`}
              sx={{
                width: AVATAR_SIZE,
                height: AVATAR_SIZE,
              }}
            >
              {member.profilePicture ? (
                <img 
                  src={member.profilePicture} 
                  alt="profile" 
                  width={AVATAR_SIZE} 
                  height={AVATAR_SIZE} 
                  style={{ borderRadius: '50%' }} 
                />
              ) : (
                <img 
                  src="/images/blank-profile.webp" 
                  alt="profile" 
                  width={AVATAR_SIZE} 
                  height={AVATAR_SIZE} 
                  style={{ borderRadius: '50%' }} 
                />
              )}
            </Box>
          ))}
          {numberOfMembers > displayedMembers.length && (
            <Box
              sx={{
                m: 0,
                bgcolor: 'black',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: AVATAR_SIZE,
                height: AVATAR_SIZE,
                borderRadius: '50%',
              }}
            >
              <Typography
                sx={{
                  color: 'white',
                  fontSize: 12
                }}
              >
                +{(numberOfMembers - displayedMembers.length).toString()}
              </Typography>
            </Box>
          )}
        </Box>
        {!userIsMember && (
          <Button 
            onClick={handleOnJoinClick}
            sx={{
              backgroundColor: "#5040FF",
              borderRadius: 25,
              width: '100%',
              height: 40,
              '&:hover': {
                backgroundColor: "#4030DD",
              }
            }}
          >
            <Typography
              sx={{
                color: 'white',
                fontSize: 14,
                fontWeight: 'bold',
                textTransform: 'none',
              }}
            >
              Join group
            </Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default GroupMembersSection;
