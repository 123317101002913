import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Box, CssBaseline } from '@mui/material';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { AuthProvider } from './components/AuthProvider';
import ProtectedRoute from "./components/ProtectedRoute";
import LoginView from "./components/LoginView";
import SignupView from "./components/SignupView";
import SessionView from "./components/SessionView";
import ProfileView from "./components/ProfileView";
import UsernameView from "./components/UsernameView";
import GroupView from './components/GroupView';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import SmartBanner from './components/SmartBanner';
import './App.css';

function App() {
  return (
    <div className="App">
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY || ""}>
        <AuthProvider>
          <Box>
            <CssBaseline />
            <Toaster
              position="top-center"
              toastOptions={{
                duration: 5000,
                style: { background: "black", color: "#EEEDEB" },
              }}
            />
            <SmartBanner 
              daysSinceClose={0}
              daysSinceView={0}
            />
            <BrowserRouter>
              <Routes>
                <Route path="/login" element={<LoginView />} />
                <Route path="/signup" element={<SignupView />} />
                <Route 
                  path="/username" 
                  element={<UsernameView />}
                />
                <Route 
                  path="/games" 
                  element={
                    <ProtectedRoute>
                      <ProfileView />
                    </ProtectedRoute>
                  }
                />
                <Route 
                  path="/" 
                  element={
                    <ProtectedRoute>
                      <ProfileView />
                    </ProtectedRoute>
                  }
                />
                <Route path="/profile/:id" element={<ProfileView />} />
                <Route path="/session/:id" element={<SessionView />} />
                <Route path="/hoopers/:id" element={<GroupView />} />
              </Routes>
            </BrowserRouter>
          </Box>
        </AuthProvider>
      </GoogleReCaptchaProvider>
    </div>
  );
}

export default App;
