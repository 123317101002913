import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, IconButton, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type InputProps = {
  daysSinceView?: number;
  daysSinceClose?: number;
}

const SmartBanner = ({
  daysSinceView = 1, 
  daysSinceClose = 1,
}: InputProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [platform, setPlatform] = useState<'ios' | 'android' | 'web'>('web');
  
  useEffect(() => {
    // Check localStorage on mount
    const lastClosed = localStorage.getItem('smartbanner-closed');
    if (lastClosed) {
      const days = (Date.now() - parseInt(lastClosed)) / (1000 * 60 * 60 * 24);
      if (days < daysSinceClose) setIsVisible(false);
    }
    const lastViewed = localStorage.getItem('smartbanner-viewed');
    if (lastViewed) {
      const days = (Date.now() - parseInt(lastViewed)) / (1000 * 60 * 60 * 24);
      if (days < daysSinceView) setIsVisible(false);
    }
    // Detect platform
    const userAgent = navigator.userAgent.toLowerCase();
    console.log("userAgent")
    if (/iphone|ipad|ipod/.test(userAgent)) {
      setPlatform('ios');
    } else if (/android/.test(userAgent)) {
      setPlatform('android');
    }
  }, [daysSinceView, daysSinceClose]);

  if (!isVisible || platform === 'web') return null;

  const appStoreLink = 'https://apps.apple.com/app/id6458646707';
  const playStoreLink = 'https://play.google.com/store/apps/details?id=com.builtdifferent.hooper';

  const handleClose = () => {
    // Save current timestamp when banner is closed
    localStorage.setItem('smartbanner-closed', Date.now().toString());
    setIsVisible(false);
  };

  const handleView = () => {
    // Save current timestamp when banner is viewed
    localStorage.setItem('smartbanner-viewed', Date.now().toString());
    setIsVisible(false);
  };

  return (
    <Paper 
      elevation={2}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        display: 'flex',
        alignItems: 'center',
        padding: 1.5,
        py: 1.75,
        bgcolor: '#5040FF',
        borderRadius: 0,
      }}
    >
      <Box
        component="img"
        src="/logo192.png"
        alt="Hooper: Basketball"
        sx={{
          width: 48,
          height: 48,
          borderRadius: 1,
          marginRight: 2
        }}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Typography sx={{ textAlign: "left", color: "white", fontWeight: "bold", fontSize: 14 }}>
          Hooper: Basketball
        </Typography>
        <Typography sx={{ textAlign: "left", color: "white", fontSize: 12 }}>
          Join for free for a limited time
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Button 
          variant="text"
          onClick={() => {
            handleView();
            window.open(
              platform === 'ios' ? appStoreLink : playStoreLink, 
              '_blank', 
              'noopener,noreferrer'
            );
          }}
          sx={{ color: "white" }}
          size="small"
        >
          VIEW
        </Button>
        <IconButton 
          size="small"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Paper>
  );
}

export default SmartBanner;