import React from "react";
import { Box, Stack, Typography, Button, Divider, Avatar } from "@mui/material";
import type { FBGroupChatMessage } from "../../shared/types";
import { getElapsedDateString } from "../../shared/utils";

const COMMENT_PREVIEW_LIMIT = 100;

type InputProps = {
  recentMessage: FBGroupChatMessage;
  handleOnViewAllClick: () => void;
}

/**
 * Session summary component
 * @param session (FBSession)
 * @param clusters (FBClusterTagV4[])
 * @param shots (ClusterActionsV4[])
 * @param owner (FBUser)
 */
const GroupChatSection = ({ recentMessage, handleOnViewAllClick }: InputProps) => {

  // Format preview message by removing extra newlines and limiting length
  const getPreviewMessage = (message: string) => {
    let preview = message.replace(/\n\s*\n/g, '\n').replace(/\n/g, " ");
    if (preview.length > COMMENT_PREVIEW_LIMIT) {
      preview = preview.slice(0, COMMENT_PREVIEW_LIMIT) + "...";
    }
    return preview;
  }

  const getLatestCommentBanner = () => {
    const elapsed = getElapsedDateString(recentMessage.createdAt);
    
    return (
      <Box
        sx={{
          flex: 1,
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.8,
          }
        }}
        onClick={handleOnViewAllClick}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            gap: '8px'
          }}
        >
          <Avatar
            src={recentMessage.profilePicture || '/images/blank-profile.webp'}
            sx={{ width: 40, height: 40 }}
          />
          <Box 
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: '4px'
            }}
          >
            <Box 
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '12px',
                alignItems: 'center'
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                {recentMessage.username}
              </Typography>
              <Typography
                sx={{
                  color: "#78808D",
                  fontSize: 12,
                }}
              >
                {elapsed}
              </Typography>
            </Box>
            <Box 
              sx={{
                display: 'flex',
                flex: 1,
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: 14,
                }}
              >
                {getPreviewMessage(recentMessage.message)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        height: 148,
        width: "100%",
        bgcolor: '#16181A',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        gap: 1,
      }}
    > 
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Typography
            sx={{ 
              color: "white",
              fontWeight: "bold",
            }}
          >
            Chat
          </Typography>
        </Stack>
        <Button
          onClick={handleOnViewAllClick}
          sx={{
            color: "#80B5FF",
            textTransform: 'none',
          }}
        >
          View all
        </Button>
      </Box>
      <Divider
        sx={{
          bgcolor: '#202429',
        }}
      />
      {recentMessage ? (
        getLatestCommentBanner()
      ) : (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pb: 1
          }}
        >
          <Typography
            sx={{
              color: "#ABB2BF",
              fontSize: 14
            }}
          >
            No chats yet
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default GroupChatSection;
