import React from "react";
import { Box, Stack, Typography, Button, Divider } from "@mui/material";
import { useMediaQuery, useTheme } from '@mui/material';
import type { FBHighlightV4 } from "../../shared/types";
import ReactPlayer from "react-player";

type InputProps = {
  recentHighlights: FBHighlightV4[];
  recentMixtapes: any;
  handleOnViewAllClick: () => void;
}

/**
 * Session summary component
 * @param session (FBSession)
 * @param clusters (FBClusterTagV4[])
 * @param shots (ClusterActionsV4[])
 * @param owner (FBUser)
 */
const GroupHighlightsSection = ({ recentHighlights, recentMixtapes, handleOnViewAllClick }: InputProps) => {

  const FavoritedHighlightCard = ({ highlight }: { highlight: FBHighlightV4 }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
      <Box 
        style={{ position: "relative", width: isSmallScreen ? 150 : 300, height: isSmallScreen ? 250 : 500 }}
        sx={{ borderRadius: 2, overflow: "hidden" }}
      >
        <ReactPlayer 
          url={highlight.video}
          light={highlight.preview}
          controls={true}
          style={{ position: "absolute", top: 0, left: 0 }}
          width="100%"
          height="100%"
        />
        <Box 
          style={{ 
            position: "absolute", 
            top: 20, 
            left: 20, 
            width: "100%", 
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 8,
          }}
        >
          <Typography style={{ color: "white", fontSize: isSmallScreen ? 14 : 18}}>
            {new Date(highlight.createdAt).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' })}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        // height: 120,
        width: "100%",
        bgcolor: '#16181A',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        gap: 1,
      }}
    > 
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Typography
            sx={{ 
              color: "white",
              fontWeight: "bold",
            }}
          >
            Highlights
          </Typography>
        </Stack>
        <Button
          onClick={() => handleOnViewAllClick()}
          sx={{
            color: "#80B5FF",
            textTransform: 'none',
          }}
        >
          View all
        </Button>
      </Box>
      <Divider
        sx={{
          bgcolor: '#202429',
        }}
      />
      {(recentHighlights && recentHighlights.length > 0) ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            overflowX: "auto",
            gap: 2,
            "&::-webkit-scrollbar": { height: 8 },
            "&::-webkit-scrollbar-thumb": { backgroundColor: "#ccc", borderRadius: 4 },
          }}
        >
          {recentHighlights.map((highlight, index) => {
            return (
              <Box key={`highlight-box-${index}`} sx={{ flexShrink: 0 }}>
                <FavoritedHighlightCard highlight={highlight} />
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pb: 1
          }}
        >
          <Typography
            sx={{
              color: "#ABB2BF",
              fontSize: 14
            }}
          >
            No highlights yet
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default GroupHighlightsSection;
