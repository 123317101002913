import React from "react";
import { Box, Stack, Typography, Button, Divider } from "@mui/material";
import { Grid } from "@mui/material";
import { 
  GAME_MODE, 
  SESSION_TAGGED, 
  SESSION_PROCESSED, 
  CURRENT_SESSION_VERSION,
} from "../../shared/constants";
import { cutoffText } from "../../shared/utils";
import type { SessionCard } from "../../shared/types";
import { useNavigate } from "react-router-dom";

type InputProps = {
  numberOfGames: any;
  recentGames: any;
  handleOnViewAllClick: () => void;
}

/**
 * Session summary component
 * @param session (FBSession)
 * @param clusters (FBClusterTagV4[])
 * @param shots (ClusterActionsV4[])
 * @param owner (FBUser)
 */
const GroupGamesSection = ({ numberOfGames, recentGames, handleOnViewAllClick }: InputProps) => {
  const navigate = useNavigate();

  const onClick = (card: SessionCard) => {
    const isClickable = 
    (card.version === CURRENT_SESSION_VERSION) && 
    (card.sessionStatus === SESSION_PROCESSED || card.sessionStatus === SESSION_TAGGED);
    if (!isClickable) return;
    if (!card.videoUrl) return;
    navigate(`/session/${card.sessionId}`);
  }

  /**
   * Get the title of the game card
   */
  const getTitle = (card: SessionCard) => {
    let title: string = "";
    if (card.sessionMode === GAME_MODE) {
      if (!card.teamNames || card.teamNames.length !== 2) {
        title = `${card.sessionType} game`;
      } else {
        const team1Name = cutoffText(card.teamNames[0], 8);
        const team2Name = cutoffText(card.teamNames[1], 8);
        title = `${team1Name} x ${team2Name}`;
      }
    } else {
      title = "Practice";
    }
    return (
      <Box>
        <Typography sx={{ color: "white", textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
          {title}
        </Typography>
      </Box>
    );
  }

  /**
   * Get the location of the game card
   */
  const getLocation = (card: SessionCard) => {
    if (!card.city || !card.state) return;
    const text = `${card.city}, ${card.state}`;
    return (
      <Typography sx={{ color: "white", textAlign: "center", fontSize: 12 }}>
        {text}
      </Typography>
    );
  }

  /**
   * Get the timestamp of the game card
   */
  const getTimestamp = (card: SessionCard) => {
    const date = new Date(card.createdAt * 1000).toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return (
      <Typography sx={{ color: "white", textAlign: "center", fontSize: 12 }}>
        {date}
      </Typography>
    );
  }

  const SessionCardSection = () => {
    return (
      <Grid container spacing={1} sx={{ width: '100%' }}>
        {recentGames.map((card: SessionCard) => {
          const isClickable = 
            (card.version === CURRENT_SESSION_VERSION) && 
            (card.sessionStatus === SESSION_PROCESSED || card.sessionStatus === SESSION_TAGGED);
          return (
            <Grid item xs={12} sm={6} md={4} lg={4} key={`gamecard-${card.sessionId}`}>
              <Box onClick={(e) => onClick(card)} sx={{ cursor: isClickable ? "pointer" : "default" }}>
                <Box 
                  sx={{ 
                    width: "100%",
                    paddingTop: "100%",
                    position: "relative",
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(${card.preview})`,
                    backgroundColor: "black",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: 1,
                    filter: isClickable ? 'none' : 'grayscale(100%)',
                    transition: 'transform 0.3s ease, filter 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.02)',
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {getTitle(card)}
                    {getLocation(card)}
                    {getTimestamp(card)}
                  </Box>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: '#16181A',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        gap: 1,
      }}
    > 
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Typography sx={{ color: "white", fontWeight: "bold" }}>
            Games
          </Typography>
          <Typography sx={{ color: "#78808D" }}>
            {numberOfGames}
          </Typography>
        </Stack>
        <Button
          onClick={() => handleOnViewAllClick()}
          sx={{ color: "#80B5FF", textTransform: 'none' }}
        >
          View all
        </Button>
      </Box>
      <Divider sx={{ bgcolor: '#202429' }} />
      {(recentGames && recentGames.length > 0) ? (
        <SessionCardSection/>
      ) : (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pb: 1
          }}
        >
          <Typography sx={{ color: "#ABB2BF", fontSize: 14 }}>
            No games yet
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default GroupGamesSection;
