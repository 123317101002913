import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Card } from "@mui/material";
import type { FBEventTicket, FBGroupEvent, GroupMember } from "../../shared/types";
import { ATTENDANCE_GOING } from "../../shared/constants";

type InputProps = {
  event: FBGroupEvent;
  source: string;
  tickets: Record<string, FBEventTicket>;
  members: GroupMember[];
  onPressEvent: () => void;
}

const EventCard = ({
  event,
  tickets,
  members,
  source,
  onPressEvent,
}: InputProps) => {
  
  const [goingList, setGoingList] = useState<GroupMember[]>([]);

  useEffect(() => {
    if (!members || !tickets) {
      return;
    }
    const goingMembers = members.filter(member => 
      tickets[member.userId]?.attendanceType === ATTENDANCE_GOING
    );
    setGoingList(goingMembers);
  }, [tickets, members]);

  return (
    <Box
      sx={{
        width: "100%",
        height: source === 'scheduleView' ? '137px' : 'auto',
        p: source === 'scheduleView' ? 2 : 0,
        borderColor: source === 'scheduleView' ? '#16181A' : 'transparent',
        borderWidth: source === 'scheduleView' ? 1 : 0
      }}
    >
      <Card
        onClick={() => onPressEvent()}
        sx={{
          display: "flex",
          flexDirection: "row",
          bgcolor: source === 'scheduleView' ? '#202429' : '#16181A',
          borderRadius: source === 'scheduleView' ? 0 : 2,
          cursor: 'pointer',
          p: 1, 
          boxShadow: 'none',
          '&:hover': {
            opacity: 0.9
          },
          
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: '20px',
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: 60,
              width: 60,
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: source === 'scheduleView' ? '#16181A' : '#202429',
              borderRadius: 0.5,
            }}
          >
            <Typography
              sx={{
                color: "#ABB2BF",
                fontWeight: "bold",
                fontSize: 14
              }}
            >
              {new Date(event.startDateTime).toLocaleString('default', { month: 'short' })}
            </Typography>
            <Typography
              sx={{
                color: "#ABB2BF",
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              {new Date(event.startDateTime).getDate()}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: 14,
                  textAlign: 'left'
                }}
              >
                {event.title}
              </Typography>
              <Typography
                sx={{
                  color: "#ABB2BF",
                  fontSize: 14
                }}
              >
                {event.description}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: 'space-between',
                gap: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                }}
              >
                <img src="/icons/friends-filled.svg" alt="friends" style={{ width: 16, height: 16 }} />
                <Typography
                  sx={{
                    color: "#ABB2BF",
                    fontSize: 14
                  }}
                >
                  {goingList?.length || 0} going
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onPressEvent();
                }}
                sx={{
                  bgcolor: 'black',
                  borderRadius: '100px',
                  width: 100,
                  height: 30,
                  color: 'white',
                  textTransform: 'none',
                }}
              >
                Going
              </Button>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onPressEvent();
                }}
                sx={{
                  bgcolor: 'black',
                  borderRadius: '100px',
                  width: 100,
                  height: 30,
                  color: 'white',
                  textTransform: 'none',
                }}
              >
                Not going
              </Button>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default EventCard;