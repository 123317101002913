import React from "react";
import { Box, Stack, Typography, Button, Divider } from "@mui/material";
import type { GroupMember } from "../../shared/types";
import EventCard from "./EventCard";

type InputProps = {
  numberOfEvents: number;
  recentEvents: any;
  recentTickets: any;
  members: GroupMember[];
  handleOnViewAllClick: () => void;
}

/**
 * Session summary component
 * @param session (FBSession)
 * @param clusters (FBClusterTagV4[])
 * @param shots (ClusterActionsV4[])
 * @param owner (FBUser)
 */
const GroupScheduleSection = ({ 
  numberOfEvents, 
  recentEvents, 
  recentTickets, 
  members, 
  handleOnViewAllClick 
}: InputProps) => {
  return (
    <Box
      sx={{
        height: recentTickets? 185 : 100,
        width: "100%",
        bgcolor: '#16181A',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        gap: 1,
      }}
    > 
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Typography sx={{ color: "white", fontWeight: "bold" }}>
            Events
          </Typography>
          <Typography sx={{ color: "#78808D" }}>
            {numberOfEvents}
          </Typography>
        </Stack>
        <Button
          onClick={handleOnViewAllClick}
          sx={{ color: "#80B5FF", textTransform: 'none' }}
        >
          View all
        </Button>
      </Box>
      <Divider
        sx={{
          bgcolor: '#202429',
        }}
      />
      {recentTickets ? (
        <EventCard 
          event={recentEvents[0]}
          tickets={recentTickets}
          members={members}
          source="scheduleSection"
          onPressEvent={handleOnViewAllClick}
        />
      ) : (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pb: 1
          }}
        >
          <Typography sx={{ color: "#ABB2BF", fontSize: 14 }}>
            No upcoming events
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default GroupScheduleSection;
