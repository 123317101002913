import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { 
  fetchGroup, 
  fetchGroupChat, 
  fetchGroupHighlights, 
  fetchGroupMembers, 
  fetchGroupSchedule, 
  fetchGroupSeasons, 
  fetchGroupSessionCards, 
  fetchGroupStatLeaders, 
} from "../shared/services";
import type { 
  FBEventTicket, 
  FBGroupChatMessage, 
  FBGroupEvent, 
  FBHighlightV4, 
  Group, 
  GroupMember, 
  GroupStatsBoxScore, 
  Season, 
  SessionCard, 
} from "../shared/types";
import { useAuth } from "./AuthProvider";
import { auth } from "../shared/firebase";
import { signOut } from "firebase/auth";
import { Box, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import MobilePopup from "./MobilePopup";
import toast from 'react-hot-toast';
import LoadingOverlay from "./LoadingOverlay";
import { analytics } from "../shared/utils";
import WebWarning from "./session/WebWarning";
import ErrorSession from "./session/ErrorSession";
import GroupAvatarSection from "./group/GroupAvatarSection";
import GroupMembersSection from "./group/GroupMembersSection";
import GroupScheduleSection from "./group/GroupScheduleSection";
import GroupChatSection from "./group/GroupChatSection";
import GroupStatsSection from "./group/GroupStatsSection";
import GroupHighlightsSection from "./group/GroupHighlightsSection";
import GroupGamesSection from "./group/GroupGamesSection";

const GroupView = () => {
  const { id } = useParams();
  // Get referrer from URL params
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get("referrer");
  const [isLessThan500, setIsLessThan500] = useState<boolean>(window.innerWidth < 500);
  // Fetch the session
  const [group, setGroup] = useState<Group>();
  const [loadingGroup, setLoadingGroup] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  
  // Fetch the user from the context
  const { user } = useAuth();
  // State for the mobile popup
  const [openPopup, setOpenPopup] = useState<boolean>(!user);
  // Navigate to the home page
  const navigate = useNavigate();

  // Add these state variables at the top with your other states
  const [stats, setStats] = useState<GroupStatsBoxScore[]>([]);
  const [members, setMembers] = useState<GroupMember[]>([]);
  const [_numberOfMembers, setNumberOfMembers] = useState<number>(group?.numberOfMembers || 1);
  const [schedule, setSchedule] = useState<FBGroupEvent[]>([]);
  const [tickets, setTickets] = useState<Record<string, Record<string, FBEventTicket>>>({} as Record<string, Record<string, FBEventTicket>>);
  const [chat, setChat] = useState<FBGroupChatMessage[]>([]);
  const [games, setGames] = useState<SessionCard[]>([]);
  const [recentGames, setRecentGames] = useState<SessionCard[]>([]);
  const [numberOfGames, setNumberOfGames] = useState<number>(0);
  const [highlights, setHighlights] = useState<FBHighlightV4[]>([]);

  // Add loading states
  const [loadingSeasons, setLoadingSeasons] = useState(false);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const [loadingChat, setLoadingChat] = useState(false);
  const [loadingGames, setLoadingGames] = useState(false);
  const [loadingHighlights, setLoadingHighlights] = useState(false);

  useEffect(() => {
    onFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsLessThan500(window.innerWidth < 500);
    });
  }, []);

  useEffect(() => {
    analytics.screen("Group", { referrer: referrer, groupId: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referrer]);

  // Entry point to fetch all the data
  const onFetchData = async () => {
    if (!id) return;
    try {
      fetchGroupCallback();
      fetchMembersCallback();
      fetchScheduleCallback();
      fetchGroupChatCallback();
      fetchGamesCallback();
      fetchHighlightsCallback();
      setIsError(false);
    } catch (error) {
      toast.error("Something went wrong. Please try again later.");
      setIsError(true);
    }
  }

  const fetchGroupCallback = async () => {
    if (!id) return;
    setLoadingGroup(true);
    try {
      const groupRes = await fetchGroup(id)
      if (groupRes) {
        setGroup(groupRes);
        if (groupRes.numberOfMembers) {
          setNumberOfMembers(groupRes.numberOfMembers)
        }
        await fetchSeasonsCallback();
      } else {
        setGroup(undefined);
      }
    } catch (error) {
      analytics.track("Web/Group: fetchGroup error", { error: error });
    } finally {
        setLoadingGroup(false);
    }
  }

  const fetchSeasonsCallback = async () => {
    if (!id) return;
    try {
      setLoadingSeasons(true);
      const seasonsResponse = await fetchGroupSeasons({ groupId: id });
      if (seasonsResponse) {        
        // Now that we have seasons, fetch stats with the correct date range
        const nowInSeconds = Math.floor(Date.now() / 1000);
        const currentSeason = seasonsResponse.find((season: Season) => {
          return season.startDate <= nowInSeconds && season.endDate >= nowInSeconds && !season.ended;
        });
        
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const todayEpoch = today.getTime() / 1000;
        const defaultFromDateEpoch = new Date(new Date().getFullYear(), 0, 1).getTime() / 1000;

        const statLeadersRequest = {
          groupId: id,
          fromDate: currentSeason?.startDate || defaultFromDateEpoch,
          toDate: todayEpoch
        };

        const groupStatLeadersRes = await fetchGroupStatLeaders(statLeadersRequest);
        if (groupStatLeadersRes) {
          setStats(groupStatLeadersRes);
        }
      }
    } catch (error) {
      toast.error("Failed to load seasons");
    } finally {
      setLoadingSeasons(false);
    }
  };

  const fetchMembersCallback = async () => {
    if (!id) return;
    try {
      setLoadingMembers(true);
      const membersResponse = await fetchGroupMembers({
        groupId: id,
        page: 0,
        limit: 30,
      });
      if (membersResponse) {
        setMembers(membersResponse);
      }
    } catch (error) {
      toast.error("Failed to load members");
    } finally {
      setLoadingMembers(false);
    }
  };

  const fetchScheduleCallback = async () => {
    if (!id) return;
    try {
      setLoadingSchedule(true);
      const scheduleResponse = await fetchGroupSchedule({
        groupId: id,
        page: 0,
        limit: 10,
      });
      if (scheduleResponse) {
        const events = scheduleResponse.map(item => item.event);
        setSchedule(events);
        const ticketsMap = scheduleResponse.reduce((acc: Record<string, Record<string, FBEventTicket>>, item) => {
          if (item.event && item.tickets) {
            acc[item.event.eventId] = item.tickets[item.event.eventId];
          }
          return acc;
        }, {} as Record<string, Record<string, FBEventTicket>>);
        setTickets(ticketsMap);
      }
    } catch (error) {
      toast.error("Failed to load schedule");
    } finally {
      setLoadingSchedule(false);
    }
  };

  const fetchGroupChatCallback = async () => {
    if (!id) return;
    try {
      setLoadingChat(true);
      const chatResponse = await fetchGroupChat({
        groupId: id,
        page: 0,
        limit: 10,
      });
      if (chatResponse) {
        chatResponse.sort((a, b) => b.createdAt - a.createdAt);
        setChat(chatResponse);
      }
    } catch (error) {
      toast.error("Failed to load chat");
    } finally {
      setLoadingChat(false);
    }
  };

  const fetchGamesCallback = async () => {
    if (!id) return;
    try {
      setLoadingGames(true);
      const gamesResponse = await fetchGroupSessionCards({
        groupId: id,
        page: 0,
        limit: 10,
      });
      if (gamesResponse) {
        setNumberOfGames(gamesResponse[0]);
        setGames(gamesResponse[1]);
        setRecentGames(gamesResponse[1].slice(0, Math.min(3, gamesResponse[1].length)));
      }
    } catch (error) {
      toast.error("Failed to load games");
    } finally {
      setLoadingGames(false);
    }
  };

  const fetchHighlightsCallback = async () => {
    if (!id) return;
    try {
      setLoadingHighlights(true);
      const highlightsResponse = await fetchGroupHighlights({
        groupId: id,
        page: 0,
        limit: 10,
      });
      if (highlightsResponse) {
        setHighlights(highlightsResponse);
      }
    } catch (error) {
      toast.error("Failed to load highlights");
    } finally {
      setLoadingHighlights(false);
    }
  };

  useEffect(() => {
    analytics.screen("Web/Group", { groupId: id, userId: user?.userId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user]);

  // Show loading overlay
  const loading = loadingGroup || !group;
  if (loading) return <LoadingOverlay />;
  // Show Error overlay
  if (isError) return <ErrorSession />;

  const handleOnPressViewAll = () => {
      toast("Members only access - You need to join the group to access more content.", {
        icon: '🔒',  // optional emoji icon
        style: {
          background: 'black',
          color: '#EEEDEB',
        },
      });
  }

  const handleOnJoinClick = async () => {
    // Navigate them to the app store link or to the app -- universal link
    setOpenPopup(true)
  }


  const LogoOverlay = () => {
    return (
      <Box 
        sx={{ position: 'absolute', top: 40, left: 40}}
        onClick={() => navigate("/")}
      >
        <img src="/logos/logo-white.svg" alt="logo" width={100} />
      </Box>
    );
  }

  const DownloadBanner = () => {
    if (user || !isLessThan500) return null;
    return (
      <Box 
        onClick={() => setOpenPopup(true)} 
        sx={{ 
          cursor: "pointer",
          backgroundColor: "#5040FF",
          py: 1,
          px: 2,
          borderRadius: 1,
          width: "100%",
          mb: 2,
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <img src="/logo192.png" alt="logo" width={32} />
        <Typography sx={{ color: "white", fontWeight: "bold" }}>
          Download the mobile app
        </Typography>
      </Box>
    );
  }

  const LoginButton = () => {
    if (user) return null;
    return (
      <Box sx={{ position: 'absolute', top: 32, right: 40 }}>
        <Box 
          onClick={() => navigate("/login")} 
          sx={{ 
            cursor: "pointer",
            backgroundColor: "#202429",
            py: 1,
            px: 2,
            borderRadius: 10,
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "bold" }}>
            Login
          </Typography>
        </Box>
      </Box>
    );
  }
  
  const LogoutButton = () => {
    if (!user) return null;
    return (
      <Box sx={{ position: 'absolute', top: 32, right: 40 }}>
        <Box 
          onClick={() => signOut(auth)} 
          sx={{ 
            cursor: "pointer",
            backgroundColor: "#202429",
            py: 1,
            px: 2,
            borderRadius: 10,
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "bold" }}>
            Logout
          </Typography>
        </Box>
      </Box>
    );
  }

  const Footer = () => {
    return (
      <Box sx={{ mb: 4 }}>
        <Typography sx={{ textAlign: "center", color: "#404040" }}>
          Copyright © 2024 Built Different AI Inc. All rights reserved.
        </Typography>
      </Box>
    );
  }

  const GroupNameBanner = () => {
    return (
      <Box sx={{ 
        backgroundColor: "#202429",
        p: 2,
        borderRadius: 2,
        width: '100%',
        display: "flex",
        alignItems: "center",
        // justifyContent: "center"
      }}>
        <Typography sx={{ color: "white", fontWeight: "bold" }}>
          {group.name}
        </Typography>
      </Box>
    );
  };

  let eventToDisplay = null;
  let recentTickets = null;
  const today = new Date(); // Get the current date
  today.setHours(0, 0, 0, 0); // Set time to 00:00:00.000
  if (!loadingSchedule && schedule?.length > 0) {
    const sortedUpcomingEvents = schedule.filter((event) => {
      const eventDate = new Date(event.startDateTime);
      return eventDate >= today; // Compare with today's date at 00:00
    }).sort((a, b) => new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime()); // Sort in ascending order
    if (sortedUpcomingEvents.length > 0) {
      eventToDisplay = sortedUpcomingEvents[0];
    }
    recentTickets = eventToDisplay ? tickets[eventToDisplay.eventId] : null; // Check if eventToDisplay is defined
  }

  return (
    <Box sx={{ minHeight: '100vh', flexDirection: 'column', display: 'flex' }}>
      <LogoOverlay />
      <LoginButton />
      <LogoutButton />
      <MobilePopup visible={openPopup} setVisible={setOpenPopup} />
      <Box 
        sx={{ 
          flex: 1,
          width: '100%',  // Full viewport width
          height: '100%', // Full viewport height
          backgroundColor: '#0c0c0d', 
          backgroundImage: 'url(/images/dot-pattern.svg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          display: 'flex',           // Use flexbox
          flexDirection: 'column',   // Stack content vertically
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: '100%', maxWidth: 1000, justifyContent: 'center' }}>
          <Box sx={{ 
            mx: 2, 
          }}>
            <Box style={{marginTop: 120}} />
            <DownloadBanner />
            <WebWarning />
            <Box sx={{ 
              mt: 2, 
              display: 'flex', 
              width: '100%',
              flexDirection: 'column',   // Stack content vertically
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2
            }}>
              {((!loadingGroup) && (group && group.groupAvatar)) &&
                <GroupAvatarSection 
                  groupAvatar={group.groupAvatar}
                />
              }
              {((!loadingGroup) && (group && group.name)) &&
                <GroupNameBanner />
              }
              {(!loadingMembers && _numberOfMembers) &&
                <GroupMembersSection
                  numberOfMembers={_numberOfMembers}
                  members={members}
                  userIsMember={members?.some(member => member?.userId === user?.userId)}
                  handleOnViewAllClick={handleOnPressViewAll}
                  handleOnJoinClick={handleOnJoinClick}
                />
              }
              {!loadingSchedule && schedule &&
                <GroupScheduleSection
                  numberOfEvents={schedule.length}
                  recentEvents={[eventToDisplay]}
                  recentTickets={recentTickets}
                  members={members}
                  handleOnViewAllClick={handleOnPressViewAll}
                />
              }
              {!loadingChat && chat &&
                <GroupChatSection
                  recentMessage={chat[0] || undefined}
                  handleOnViewAllClick={handleOnPressViewAll}
                />
              }
              {!loadingSeasons && !loadingMembers && stats &&
                <GroupStatsSection
                  stats={stats.length > 0 ? stats : undefined}
                  members={members}
                  handleOnViewAllClick={handleOnPressViewAll}
                />
              }
              {!loadingHighlights && highlights &&
                <GroupHighlightsSection
                  recentHighlights={highlights.length > 0 ? highlights : []}
                  recentMixtapes={[]}
                  handleOnViewAllClick={handleOnPressViewAll}
                />
              }
              {!loadingGames && games &&
                <GroupGamesSection
                  numberOfGames={numberOfGames}
                  recentGames={recentGames.length > 0 ? recentGames : []}
                  handleOnViewAllClick={handleOnPressViewAll}
                />
              }
            </Box>
            <Box sx={{ mt: 10}}>
              <Footer />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GroupView;
