// URL to make API requests to
export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || "";
export const SEGMENT_KEY = process.env.REACT_APP_SEGMENT_KEY || "";
export const PRIVACY_POLICY: string = "https://www.hooper.gg/privacy-policy";

export const USER_TYPE_USER = "user";
export const SUBSCRIPTION_TYPE_BETA = "beta";

// Session constants
export const NUM_SESSIONS_IN_GAMES: number = 50;
export const CURRENT_SESSION_VERSION: number = 4;

// Session modes
export const GAME_MODE: string = "game";
export const SHOOTAROUND_MODE: string = "shootaround";

// Action types
export const ACTION_SHOT: number = 1;
export const ACTION_REBOUND: number = 2;
export const ACTION_ASSIST: number = 3;

// Session statuses
export const SESSION_CREATED: string = "created";
export const SESSION_UPLOADING: string = "uploading";
export const SESSION_UPLOADED: string = "uploaded";
export const SESSION_WAITING: string = "waiting";
export const SESSION_PROCESSED: string = "processed";
export const SESSION_TAGGED: string = "tagged";
export const SESSION_ERROR: string = "error";

// Points systems
export const POINTS_TWOS_AND_THREES: number = 1;
export const POINTS_ONES_AND_TWOS: number = 2;
export const POINTS_ONES_ONLY: number = 3;

// Session types
export const SESSION_SOLO: string = "solo";
export const SESSION_1v1: string = "1v1";
export const SESSION_2v2: string = "2v2";
export const SESSION_3v3: string = "3v3";
export const SESSION_4v4: string = "4v4";
export const SESSION_5v5: string = "5v5";

// Hardware mode
export const TRIPOD_SETUP: string = "tripod";
export const GROUND_SETUP: string = "ground";

// Shot status
export const SHOT_STATUS_MAKE: number = 1;
export const SHOT_STATUS_MISS: number = 2;

// Event attendance types
export const ATTENDANCE_GOING: string = 'going';
export const ATTENDANCE_NOT_GOING: string = 'notGoing';
export const ATTENDANCE_MAYBE: string = 'maybe';

// Shot Chart region polygon coordinate
// paint left, paint right, free throw right, free throw left
export const PAINT_REGION_COORDINATES: [number, number][] = [
  [771.82,7.989],[1210.29,7.633],[1210.669,655.017],[771.834,654.884],
];
export const TWO_POINT_REGION_COORDINATES: [number, number][] = [
  [124.923,7.348],
  [125.424,51.073],
  [127.899,88.264],
  [132.895,125.99],
  [144.693,190.727],
  [162.953,257.016],
  [188.677,331.305],
  [238.538,434.172],
  [283.338,505.035],
  [326.848,561.277],
  [380.286,619.488],
  [434.213,668.829],
  [501.163,719.514],
  [568.294,761.389],
  [644.038,799.05],
  [716.767,827.845],
  [798.902,850.956],
  [879.287,866.672],
  [945.242,872.879],
  [1003.187,874.17],
  [1068.261,871.318],
  [1140.304,861.939],
  [1205.169,850.503],
  [1293.785,821.722],
  [1357.488,796.648],
  [1413.578,769.157],
  [1471.745,734.618],
  [1534.889,689.301],
  [1591.759,640.853],
  [1650.418,580.228],
  [1693.869,527.894],
  [1724.399,483.339],
  [1755.618,433.11],
  [1778.251,390.504],
  [1814.616,306.149],
  [1838.66,233.36],
  [1853.892,166.583],
  [1864.2,100.981],
  [1868.276,55.518],
  [1868.598,7.965],
];
export const THREE_POINT_REGION_COORDINATES: [number, number][] = [
  [9.974,7.836], [10.485,1399.652], [1973.859,1400.127], [1973.087,8.432],
];
export const THREE_POINT_REGION_POLYGON: [number, number][] = [
  [120, 0], [125, 65], [150, 205], [180, 297], [215, 385],
  [260, 464], [312, 540], [385, 620], [480, 700], [565, 750],
  [650, 795], [740, 829], [850, 852], [985, 863], [1090, 857],
  [1215, 834], [1305, 805], [1385, 769], [1460, 726], [1545, 665],
  [1645, 568], [1720, 471], [1765, 391], [1800, 316], [1830, 226],
  [1850, 141], [1855, 72], [1857, 0], [120, 0],
];

// Shot Chart zone polygon coordinates
export const BLOCK_LEFT_COORDINATES: [number, number][] = [
  [771.187, 324.627], [771.297, 8.188], [124.768, 7.472], [125.91, 73.116], 
  [136.499, 153.617], [155.852, 236.994], [184.536, 321.954]
];
export const ELBOW_LEFT_COORDINATES: [number, number][] = [
  [184.361, 321.942], [771.252, 324.642], [773.466, 844.886], [714.075, 827.441], 
  [635.301, 796.042], [547.219, 749.147], [443.265, 677.323], [352.258, 591.392], 
  [277.066, 495.536], [210.628, 381.406]
];
export const PAINT_UPPER_RIGHT_COORDINATES: [number, number][] = [
  [988.127, 7.327], [1209.76, 7.603], [1210.513, 327.371], [988.863, 326.907]
];
export const PAINT_UPPER_LEFT_COORDINATES: [number, number][] = [
  [772.026, 8.137], [988.045, 7.417], [988.928, 326.925], [771.223, 324.546]
];
export const PAINT_BOTTOM_RIGHT_COORDINATES: [number, number][] = [
  [988.769, 327.244], [1210.121, 327.623], [1210.956, 654.415], [990.13, 653.952]
];
export const PAINT_BOTTOM_LEFT_COORDINATES: [number, number][] = [
  [771.032, 325.396], [771.648, 654.792], [989.667, 653.677], [988.652, 327.438]
];
export const KEY_ZONE_COORDINATES: [number, number][] = [
  [771.954, 655.158], [1210.911, 655.18], [1211.82, 847.001], [1169.857, 856.645], 
  [1134.454, 862.7], [1058.569, 871.86], [991.713, 874.479], [914.545, 869.869], 
  [836.908, 859.801], [773.542, 845.042]
];
export const BLOCK_RIGHT_COORDINATES: [number, number][] = [
  [1210.017, 7.604], [1869.772, 7.874], [1866.72, 80.441], [1856.928, 156.383], 
  [1835.604, 244.238], [1805.562, 331.602], [1210.872, 327.507]
];
export const ELBOW_RIGHT_COORDINATES: [number, number][] = [
  [1805.593, 331.484], [1210.58, 327.531], [1211.718, 847.317], [1320.654, 811.857], 
  [1413.673, 768.463], [1500.043, 714.832], [1584.233, 646.864], [1658.062, 570.82], 
  [1717.714, 494.674], [1775.048, 397.535]
];
export const WING_LEFT_COORDINATES: [number, number][] = [
  [10.275, 561.462], [11.485, 1399.699], [771.615, 1399.977], [773.456, 845.048], 
  [721.443, 829.759], [650.764, 802.614], [591.145, 774.177], [528.46, 738.921], 
  [450.123, 682.684], [405.262, 643.679], [355.488, 594.147], [328.564, 563.393]
];
export const MIDDLE_THREE_POINT_COORDINATES: [number, number][] = [
  [773.44, 845.021], [837.304, 859.86], [879.157, 866.016], [924.214, 870.778], 
  [992.063, 874.408], [1063.706, 871.516], [1119.787, 865.678], [1171.77, 856.73], 
  [1212.118, 846.836], [1214.206, 1399.741], [771.954, 1400.083]
];
export const WING_RIGHT_COORDINATES: [number, number][] = [
  [1214.501, 1399.414], [1974.655, 1399.321], [1974.173, 562.636], [1665.636, 562.486], 
  [1609.073, 624.881], [1548.215, 678.665], [1482.804, 726.418], [1424.332, 762.773], 
  [1354.205, 797.954], [1274.079, 828.539], [1212.166, 846.834]
];
export const CORNER_LEFT_COORDINATES: [number, number][] = [
  [327.295, 563.291], [11.231, 562.225], [10.215, 7.799], [124.935, 7.845], 
  [125.993, 49.249], [131.712, 114.09], [140.814, 174.289], [155.199, 234.712], 
  [185.128, 322.911], [207.095, 373.854], [244.576, 444.762], [295.94, 522.952]
];
export const CORNER_RIGHT_COORDINATES: [number, number][] = [
  [1665.411, 561.975], [1974.733, 563.784], [1973.628, 8.116], [1869.194, 8.954], 
  [1869.52, 32.163], [1867.708, 74.029], [1862.557, 120.468], [1850.777, 186.368], 
  [1837.898, 236.854], [1811.99, 315.989], [1769.494, 407.736], [1712.821, 501.256]
];
export const SHOT_CHART_WIDTH  = 1978;
export const SHOT_CHART_HEIGHT = 1406;