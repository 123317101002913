import React from 'react';
import { Box } from '@mui/material';

type Props = {
  groupAvatar: string;
}

const GroupAvatarSection = ({ groupAvatar }: Props) => {
  return (
    <Box sx={{ width: '100%', height: 200, backgroundColor: '#16181A', borderRadius: 8 }}>
      {groupAvatar && (
        <img 
          src={groupAvatar} 
          alt="Group avatar"
          style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 8 }}
        />
      )}
    </Box>
  );
};

export default GroupAvatarSection;
