import { axiosInstance } from "./axios";
import { NUM_SESSIONS_IN_GAMES } from "./constants";
import { PUBLIC_ENDPOINT, USER_ENDPOINT } from "./endpoints";
import type { 
  FBUser, 
  FBSession, 
  FBInvite, 
  SessionCard, 
  FBHighlightV4, 
  FBTagV4, 
  FBClusterTagV4, 
  FBShotV4,
  Group,
  GetGroupHighlightsRequest,
  GetGroupSessionCardsRequest,
  GetGroupChatRequest,
  GetGroupScheduleRequest,
  GetGroupMembersRequest,
  GetGroupStatsRequest,
  GetGroupSeasonsRequest,
  Season,
  FBGroupChatMessage,
  EventTickets,
  GroupMember,
  GroupStatsBoxScore,
} from "./types";
import type { 
  WebInviteRequest, 
  WebReCaptchaRequest, 
  CreateUserRequest, 
  UpdateUserUsernameRequest,
} from "./types";
import { standardizeText } from "./utils";

/** 
 * Gets a user using the logged in user
 * @returns (FBUser)
 */
export const fetchUser = async (user_id: string): Promise<FBUser> => {
  return axiosInstance
  .get(`${USER_ENDPOINT}/public/${user_id}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchUser`:", err));
}

/**
 * Fetch all sessions by page
 * @param userId (string)
 * @param page (number)
 * @param limit (number)
 * @param version (number)
 * @returns (SessionCard[])
 */
export const fetchSessionCards = async (
  userId: string,
  page: number = 0,
  limit: number = NUM_SESSIONS_IN_GAMES,
): Promise<SessionCard[]> => {
  return axiosInstance
  .get(`${PUBLIC_ENDPOINT}/cards/${userId}`, { params: { page: page, limit: limit }})
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchSessionCards`:", err));
}

/**
 * Fetch the raw session object
 * @param sessionId (string)
 * @param version (number)
 * @returns (FBSession)
 */
export const fetchSession = async (sessionId: string): Promise<FBSession> => {
  return axiosInstance
  .get(`${PUBLIC_ENDPOINT}/${sessionId}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchSession`:", err));
}

/**
 * Fetch the session highlights
 * @param sessionId (string)
 * @returns (FBHighlightV4[])
 */
export const fetchSessionHighlights = async (sessionId: string): Promise<FBHighlightV4[]> => {
  return axiosInstance
  .get(`${PUBLIC_ENDPOINT}/highlights/${sessionId}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchSessionHighlights`:", err));
}

/**
 * Fetch the session tags
 * @param sessionId (string)
 * @param version
 * @returns (FBTagV4[])
 */
export const fetchSessionTags = async (sessionId: string): Promise<FBTagV4[]> => {
  return axiosInstance
  .get(`${PUBLIC_ENDPOINT}/tags/${sessionId}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchSessionTags`:", err));
}

/**
 * Fetch the session shots
 * @param sessionId (string)
 * @returns (PlayerShotChart[])
 */
export const fetchSessionShots = async (sessionId: string): Promise<FBShotV4[]> => {
  return axiosInstance
  .get(`${PUBLIC_ENDPOINT}/shots/${sessionId}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchSessionShots`:", err));
}

/**
 * Fetch all cluster tags for a session
 * @param sessionId (string)
 * @returns (FBClusterTagV4[])
 */
export const fetchSessionClusters = async (sessionId: string): Promise<FBClusterTagV4[]> => {
  return axiosInstance
  .get(`${PUBLIC_ENDPOINT}/clusters/${sessionId}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchClusterTags`:", err));
}

/**
 * Fetch raw tagged users for a session
 * @param sessionId (string)
 * @param version
 * @returns (FBUser[])
 */
export const fetchTaggedUsers = async (sessionId: string): Promise<FBUser[]> => {
  return axiosInstance
  .get(`${PUBLIC_ENDPOINT}/tagged/${sessionId}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchTaggedUsers`:", err));
}

/**
 * Invite a user to the app
 * @param inviterId (string)
 * @param inviteeId (string)
 * @returns (FBInvite)
 */
export const recordInvitedUser = async (inviterId: string, inviteeId: string): Promise<FBInvite> => {
  const config = { headers: { "Content-Type": "application/json" } };  // to send as json
  const body: WebInviteRequest = { inviterId, inviteeId };
  return axiosInstance
  .post(`/record/invite`, body, config)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `inviteUser`:", err));
}

/**
 * Verify a reCAPTCHA token
 * @param token (string)
 * @returns (boolean)
 */
export const verifyReCaptcha = async (token: string): Promise<boolean> => {
  const body: WebReCaptchaRequest = { token };
  return axiosInstance
  .post(`/verify/recaptcha`, body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `verifyReCaptcha`:", err);
    return false;
  });
}

/** 
 * Create a user
 * @param body (CreateUserRequest)
 * @returns (FBUser)
 */
export const createUser = async (body: CreateUserRequest): Promise<FBUser> => {
  const config = { headers: { "Content-Type": "application/json" } };  // to send as json
  return axiosInstance
  .post(`${USER_ENDPOINT}`, body, config)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `createUser`:", err));
}

/** 
 * Update username for a user
 * @param body (UpdateUserUsernameRequest)
 * @returns (FBUser)
 */
export const updateUserUsername = async (body: UpdateUserUsernameRequest): Promise<FBUser> => {
  const config = { headers: { "Content-Type": "application/json" } };  // to send as json
  return axiosInstance
  .post(`${USER_ENDPOINT}/username`, body, config)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `updateUserUsername`:", err);
  });
}

/**
 * Check if a user exists
 * @param userId (string)
 * @returns (FBUser)
 */
export const checkUser = async (userId: string): Promise<FBUser> => {
  return axiosInstance
  .get(`${USER_ENDPOINT}/${userId}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `checkUser`:", err));
}

/**
 * Check if a username exists
 * @param username (string)
 * @returns (bool)
 */
export const checkUserUsername = async (username: string): Promise<boolean> => {
  username = standardizeText(username);
  return axiosInstance
  .get(`${USER_ENDPOINT}/check/username/${username}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `checkUser`:", err));
}

/**
 * Fetch the raw group object
 * @param groupId (string)
 * @returns (Group)
 */
export const fetchGroup = async (groupId: string): Promise<Group> => {
  return axiosInstance
  .get(`${PUBLIC_ENDPOINT}/group/${groupId}`)
  .then((res) => res.data)
  .catch((err) => console.error("Error in `fetchGroup`:", err));
}

/** 
 * Return members of group
 */
export const fetchGroupSeasons = async (req: GetGroupSeasonsRequest): Promise<Season[]> => {
  return axiosInstance
  .get(`${PUBLIC_ENDPOINT}/group/seasons/${req.groupId}`)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchGroupSeasons`:", err);
  });
}

/** 
 * Return stat leaders of group
 */
export const fetchGroupStatLeaders = async (req: GetGroupStatsRequest): Promise<GroupStatsBoxScore[]> => {
  return axiosInstance
  .get(`${PUBLIC_ENDPOINT}/group/stats/leaders/${req.groupId}`, { 
    params: { 
      group_id: req.groupId,
      from_date: req.fromDate,
      to_date: req.toDate
    } 
  })
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchGroupStatLeaders`:", err);
  });
}

/** 
 * Return members of group
 */
export const fetchGroupMembers = async (req: GetGroupMembersRequest): Promise<GroupMember[]> => {
  return axiosInstance
  .get(`${PUBLIC_ENDPOINT}/group/members/${req.groupId}`, { 
    params: { 
      groupId: req.groupId,
      page: req.page || 0,
      limit: req.limit || 10,
    } 
  })
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchGroupMembers`:", err);
  });
}

/** 
 * Return schedule of group
 */
export const fetchGroupSchedule = async (req: GetGroupScheduleRequest): Promise<EventTickets[]> => {
  return axiosInstance
  .get(`${PUBLIC_ENDPOINT}/group/schedule/${req.groupId}`, { 
    params: { 
      groupId: req.groupId,
      page: req.page || 0,
      limit: req.limit || 10,
    } 
  })
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchGroupSchedule`:", err);
  });
}

/** 
 * Return chat of group
 */
export const fetchGroupChat = async (req: GetGroupChatRequest): Promise<FBGroupChatMessage[]> => {
  return axiosInstance
  .get(`${PUBLIC_ENDPOINT}/group/chat/${req.groupId}`, { 
    params: { 
      groupId: req.groupId,
      page: req.page || 0,
      limit: req.limit || 10,
    } 
  })
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchGroupChat`:", err);
  });
}

/** 
 * Return games of group
 */
export const fetchGroupSessionCards = async (req: GetGroupSessionCardsRequest): Promise<[number, SessionCard[]]> => {
  return axiosInstance
  .get(`${PUBLIC_ENDPOINT}/group/cards/${req.groupId}`, { 
    params: { 
      groupId: req.groupId,
      page: req.page || 0,
      limit: req.limit || 10,
    } 
  })
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchGroupSessionCards`:", err);
  });
}

  /** 
 * Return highlights of group
 */
export const fetchGroupHighlights = async (req: GetGroupHighlightsRequest): Promise<FBHighlightV4[]> => {
  return axiosInstance
  .get(`${PUBLIC_ENDPOINT}/group/highlights/${req.groupId}`, { 
    params: { 
      groupId: req.groupId,
      page: req.page || 0,
      limit: req.limit || 10,
    } 
  })
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchGroupHighlights`:", err);
  });
}